import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AddReceving from "./AddReceving";
import RecevingDetails from "./RecevingDetails";
import ReceivingReport from "./ReceivingReport";
import { isNull } from "lodash";
import ReceivingReportByDate from "./ReceivingReportByDate";
import ReceivingDetailWithVendor from "./ReceivingDetailWithVendor";

const Receving = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      <Tabs
        defaultActiveKey={
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 74)
            ? "RecList"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 75)
            ? "AddRec"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 76)
            ? "RecReport"
            : ""
        }
        id="RecList"
        className="mb-3"
        unmountOnExit={true}
      >
        <Tab
          eventKey="RecList"
          title="GRN/PO Detail"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 74)
              ? false
              : true
          }
        >
          <RecevingDetails />
        </Tab>
        <Tab
          eventKey="RecListWithVendor"
          title="GRN/PO With Vendor Search"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 74)
              ? false
              : true
          }
        >
          <ReceivingDetailWithVendor />
        </Tab>
        <Tab
          eventKey="AddRec"
          title="Add GRN/PO"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 75)
              ? false
              : true
          }
        >
          <AddReceving />
        </Tab>

        <Tab
          eventKey="RecReport"
          title="GRN/PO Report By Id"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 76)
              ? false
              : true
          }
        >
          <ReceivingReport />
        </Tab>
        <Tab
          eventKey="RecReportBYDate"
          title="GRN/PO Report By Date"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 76)
              ? false
              : true
          }
        >
          <ReceivingReportByDate />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Receving);
