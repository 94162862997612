import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";
import {
  getAllInventory,
  getWarehouseData,
  getProductByVendor,
  addPacking,
} from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter, sumBy, intersectionBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getVendor } from "../Vendor/vendor.action";

const AddPacking = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryFromId: "",
      inventoryFromLabel: "",
      inventoryToId: "",
      inventoryToLabel: "",
      quantity: 0,
    },
  ]);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [wareHouseId, setwareHouseId] = useState("");
  const [wareHouselabel, setwareHouseLabel] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [requistionLabel, setRequistionLabel] = useState();
  const [department, setdepartment] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [vendorId, setVendorId] = useState();

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Packing Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeWareHouse = (selectedOption) => {
    setwareHouseId(selectedOption.value);
    setwareHouseLabel(selectedOption.label);
  };

  function uploadPurchase() {
    props.addPacking(
      {
        date: date,
        description: requistionType,
        data: inputField,
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistionType("");
    setReceivedBy("");
    setIssuedBy("");
    setRequistionLabel("");
    setdepartment("");
    setRequistion("-1");
    setInputField([
      {
        inventoryFromId: "",
        quantity: "",
        inventoryFromLabel: "",
        inventoryToId: "",
        inventoryToLabel: "",
      },
    ]);
  }
  const inventory = !isEmpty(props.allInventory) && props.allInventory.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.warehouse.whKey +
        "-" +
        x.category.cateKey +
        "-" +
        x.invKey +
        " " +
        x.productName,
      productType: x.productType,
      // whId: x.warehouse.wareHouseId,
    };
    return data;
  });
  const getWareHouseList = props.warehouseList.map((x) => {
    let data = { value: x.wareHouseId, label: x.wareHouseName };
    return data;
  });
  const filteredInventoryWIP = filter(inventory, { productType: "WIP" });
  const filteredInventoryRaw = filter(inventory, { productType: "Raw Material" });
  const filteredArray = filteredInventoryWIP.concat(filteredInventoryRaw)
  const filteredInventoryFinshed = filter(inventory, { productType: "Finished" });
  // intersectionBy(inventory, [{ 'productType': "WIP" }, { 'productType': "Raw Material" }], 'productType');
  // filter(inventory, { productType: ["WIP", "Raw Material"] });

  console.log(filteredInventoryWIP, filteredInventoryRaw, filteredArray, "init filter");

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.allInventory, (x) => x.inventoryId == item);

    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryFromId"] = e.value;
    if (type == "intname") list[index]["inventoryFromLabel"] = e.label;
    if (type == "intnamefinshed") list[index]["inventoryToId"] = e.value;
    if (type == "intnamefinshed") list[index]["inventoryToLabel"] = e.label;
    // if (type == "intname")
    //   list[index]["quantity"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") {
      list[index]["quantity"] =
      Number(e.target.value) < 0 ? alert("cannot added less than 0") : Number(e.target.value);
      // list[index]["total_price"] = Number(list[index]['price']) * Number(e.target.value)
    }
    // if (type == 'intPrice') {
    //   list[index]['price'] = Number(e.target.value)
    //   list[index]["total_price"] = Number(list[index]['quantity']) * Number(e.target.value)
    // }

    setInputField(list);
  };

  const handleChange = (e, index, type) => {
    let z = find(props.allInventory, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    console.log(z, "testttttttttttttt")
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryFromId"] = e.value;
    if (type == "intname") list[index]["inventoryFromLabel"] = e.label;

    if (type == "intnamefinshed") list[index]["inventoryToId"] = e.value;
    if (type == "intnamefinshed") list[index]["inventoryToLabel"] = e.label;

    // if (type == "intname") list[index]["price"] = z.productPrice;
    if (type == "intname")
      list[index]["productQuantityCount"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") list[index]["quantity"] = Number(e.target.value);
    setInputField(list);
    // list[index]['quantity'] = "";
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      {
        inventoryFromId: "",
        inventoryFromLabel: "",
        inventoryToId: "",
        inventoryToLabel: "",
        quantity: 0,
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  const getRequistion = [
    { value: "Manual", label: "Manual" },
    { value: "After Production", label: "After Production" },
  ];
  const getVendorList =
    !isEmpty(props.vendorInfo) &&
    props.vendorInfo.map((x) => {
      let data = { value: x.vendor_id, label: x.vendor_name };
      return data;
    })
  const handleChangeRequistion = (selectedOption) => {
    setRequistion(selectedOption.value);
    setRequistionLabel(selectedOption.label);
  };
  const handleChangeVendor = (selectedOption) => {
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
    props.getProductByVendor(selectedOption.value)
  };

  useEffect(() => {
    props.getAllInventory({
      startedDate: "",
      endDate: "",
      wareHouseId: "",
      subCategoryId: "",
      categoryId: "",
    });
    // props.getVendor();

  }, []);
  return (
    <>
      {props.isFetchingInventoryAll || props.isFetchingGetVendor ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="3" lg="3" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date...."
                    name="Date"
                    value={date}
                    onChange={(e) =>
                      setDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl="9" lg="9" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description...."
                    name="requistionType"
                    value={requistionType}
                    onChange={(e) => setRequistionType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory From<span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryFromLabel }}
                          placeholder="Select Inventory From..."
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          // options={isEmpty(inventory) || (wareHouseId === "") ? [] : filter(inventory, { whId: wareHouseId })}
                          options={
                            isEmpty(filteredArray)
                              ? []
                              : filteredArray
                          }
                          isDisabled={isEmpty(filteredArray)}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(
                                inputField,
                                (x) => x.inventoryFromId == option.value
                              )
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Inventories Quantity <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Inventories Quantity...."
                          name="productQuantityCount"
                          disabled
                          value={item.productQuantityCount}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Inventory Price <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Inventory Price...."
                          type="number"
                          // disabled
                          value={item.price}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              "intPrice",
                              item.inventoryId
                            )
                          }
                        />
                      </Form.Group>
                    </Col> */}
                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="quantity"
                          min={0}
                          value={item.quantity}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              "intquantity",
                              item.inventoryFromId
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory To<span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryToLabel }}
                          placeholder="Select Inventory To..."
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intnamefinshed")}
                          // options={isEmpty(inventory) || (wareHouseId === "") ? [] : filter(inventory, { whId: wareHouseId })}
                          options={
                            isEmpty(filteredInventoryFinshed)
                              ? []
                              : filteredInventoryFinshed
                          }
                          isDisabled={isEmpty(filteredInventoryFinshed)}
                          // isOptionDisabled={(option) =>
                          //   !isEmpty(
                          //     find(
                          //       inputField,
                          //       (x) => x.inventoryToId == option.value
                          //     )
                          //   )
                          // }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <IconButton
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {inputField.length !== 1 && (
                          <IconButton
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {
                  date == "" ||
                  inputField[0].inventoryFromId == "" ||
                  inputField[0].inventoryToId == "" ||
                  inputField[0].quantity == 0 ? (
                  <Button
                    disabled
                    style={{ color: "black" }}
                    className="sendButton"
                  >
                    Save
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={uploadPurchase}>
                    Save
                  </Button>
                )
              }
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addPacking: (data, handleVisible, handleDangerVisible) =>
      dispatch(addPacking(data, handleVisible, handleDangerVisible)),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    getWarehouseData: () => dispatch(getWarehouseData()),
    getVendor: () => dispatch(getVendor()),
    getProductByVendor: (id) => dispatch(getProductByVendor(id))
  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  warehouseList: state.AddDataReducer.warehouseList,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  productByVendorList: state.AddDataReducer.productByVendorList,
  vendorInfo: state.vendorReducer.vendorInfo,
  isFetchingGetVendor: state.vendorReducer.isFetchingGetVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPacking);
