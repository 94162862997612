import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddPacking from "./AddPacking";
import PackingDetails from "./PackingDetails";

const TotalPacking = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      <Tabs
        defaultActiveKey={
          // "addPacking"
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 121)
            ? "addPacking"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 122)
            ? "getPacking"
            : ""
        }
        id="packing"
        className="mb-3"
        unmountOnExit={true}
      >
          <Tab
          eventKey="addPacking"
          title="Add Packing"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 121)
              ? false
              : true
          }
        >
          <AddPacking />
        </Tab>
        <Tab
          eventKey="getPacking"
          title="Packing List"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 122)
              ? false
              : true
          }
        >
          <PackingDetails />
        </Tab>
      

        {/* <Tab
          eventKey="RecReport"
          title="GRN/PO Report By Id"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 76)
              ? false
              : true
          }
        >
          <ReceivingReport />
        </Tab> */}
        {/* <Tab
          eventKey="RecReportBYDate"
          title="GRN/PO Report By Date"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 76)
              ? false
              : true
          }
        >
          <ReceivingReportByDate />
        </Tab> */}
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalPacking);
