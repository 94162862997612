import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { ModalBody, Modal, ModalFooter, ModalHeader } from "reactstrap";
import "./add-details.css";
import {
  getCategoryData,
  getWarehouseData,
  getsubCategoryData,
  addInventory,
  getInventory,
  deleteInventory,
  updateInventory,
  resetReducer,
  uploadFile,
  getAllInventory,
  categoryByWareHouse,
  subCategoryByCategory,
  getMasterControl,
  getInvMasterControl,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty,filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { getVendor } from "./Vendor/vendor.action";

const AddInventory = (props) => {
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryId1, setSubCategoryId1] = useState();
  const [wareHouseId, setwareHouseId] = useState();
  const [categoryId, setcategoryId] = useState();
  const [wareHouseId1, setwareHouseId1] = useState();
  const [masterControlId, setMasterControlId] = useState();
  const [masterControlLabel, setMasterControlLabel] = useState();
  const [categoryId1, setcategoryId1] = useState();
  const [companyName, setCompanyName] = useState();
  const [inventoryName, setInventoryName] = useState();
  const [price, setPrice] = useState();
  const [arrivalDate, setArrivalDate] = useState();
  const [vendor, setVendor] = useState();
  const [description, setDescription] = useState();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [miniBalance, setMiniBalance] = useState();
  const [maxBalance, setMaxBalance] = useState();
  const [show, setShow] = useState(false);
  const [attributeType, setAttributeType] = useState([]);
  const [file, setFile] = useState();
  // const [size, setSize] = useState();
  // const [color, setColor] = useState();
  const [invField, setInvField] = useState();
  const [invFieldLabel, setInvFieldLabel] = useState();
  const [productType, setProductType] = useState();
  const [productTypeLabel, setProductTypeLabel] = useState();
  const [currency, setCurrency] = useState();
  const [currencyLabel, setCurrencyLabel] = useState();
  const [uom, setUom] = useState();
  const [rackPosition, setRackPosition] = useState();
  const [boxNo, setBoxNo] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  const [thickness, setThickness] = useState();
  const [hsCode, setHsCode] = useState();
  const [description2, setDescription2] = useState();
  const [description3, setDescription3] = useState();
  const [description4, setDescription4] = useState();
  const [vendorName, setVendorName] = useState();
  const [vendorId, setVendorId] = useState();

  const [sizeId, setSizeId] = useState('');
  const [modalId, setModalId] = useState('');
  const [colorId, setColorId] = useState('');
  const [size, setSize] = useState();
  const [modal, setModal] = useState();
  const [color, setColor] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Inventory Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadInventory() {
    let mapped = attributeType.map((item) => item);
    var newObj = Object.assign({}, ...mapped);
    props.addInventory(
      {
        companyName: companyName,
        productName: inventoryName,
        productPrice: price,
        arrival_date: arrivalDate,
        // VendorName: vendor,
        invertoryDesc1: description,
        currentBlnc: currentBalance,
        minBlnc: miniBalance,
        maxBlnc: maxBalance,
        wareHouseId: wareHouseId1,
        categoryId: categoryId1,
        vendor_id: vendorId,
        subCategoryId: subCategoryId,
        inv_variation_model:modalId,
        inv_variation_size:sizeId,
        inv_variation_colour:colorId,
        inventoryField: invField,
        productType: productType,
        currency: currency,
        uom: masterControlLabel,
        rackPosition: rackPosition,
        box: boxNo,
        height: height,
        width: width,
        length: length,
        thickness: thickness,
        hsCode: hsCode,
        // invertoryDesc2: description2,
        // invertoryDesc3: description3,
        // invertoryDesc4: description4,
        userId: window.localStorage.getItem("userId"),
        ...newObj,
      },
      handleVisible,
      handleDangerVisible
    );
    setCompanyName("");
    setInventoryName("");
    setPrice("");
    setArrivalDate("");
    setVendor("");
    setDescription("");
    setCurrentBalance(0);
    setMiniBalance("");
    setMaxBalance("");
    setSize("");
    setColor("");
    setInvFieldLabel("");
    setProductTypeLabel("");
    setCurrencyLabel("");
    setUom("");
    setRackPosition("");
    setBoxNo("");
    setHeight("");
    setWidth("");
    setLength("");
    setThickness("");
    setHsCode("");
    setDescription2("");
    setDescription3("");
    setDescription4("");
    setwareHouseId("");
    setcategoryId("");
    setSubCategoryId1("");
    setVendorId("")
    setVendorName("")
    setModalId('')
    setModal("")
    setSizeId('')
    setSize("")
    setColorId('')
    setColor("")
  }

  const getWareHouseList = props.warehouseList.map((x) => {
    let data = { value: x.wareHouseId, label: x.wareHouseName };
    return data;
  });
  const getcategoryList =
    !isEmpty(props.categoryByWareHouseValue) &&
    props.categoryByWareHouseValue.map((x) => {
      let data = { value: x.categoryId, label: x.categoryName };
      return data;
    });
  const getSubCategoryList =
    !isEmpty(props.subCategoryByCategoryValue) &&
    props.subCategoryByCategoryValue.map((x) => {
      let data = { value: x.subCategoryId, label: x.subCategoryName };
      return data;
    });
  const getVendorList =
    !isEmpty(props.vendorInfo) &&
    props.vendorInfo.map((x) => {
      let data = { value: x.vendor_id, label: x.vendor_name };
      return data;
    });
  const getMasterControlList = props.masterControlList.map((x) => {
    let data = { value: x.master_control_inventory_id, label: x.unit };
    return data;
  });
  const getInventoryField = [
    { value: "Local", label: "Local" },
    { value: "Imported", label: "Imported" },
  ];

  const getProductType = [
    { value: "Raw Material", label: "Raw Material" },
    { value: "WIP", label: "WIP" },
    { value: "Finished", label: "Finished" },
  ];

  const getCurrency = [
    { value: "PKR", label: "PKR" },
    { value: "Dollar", label: "Dollar" },
    { value: "Pound", label: "Pound" },
    { value: "Euro", label: "Euro" },
  ];

  const handleChangeMasterControl = (selectedOption) => {
    setMasterControlId(selectedOption.value);
    setMasterControlLabel(selectedOption.label);
  };
  const handleChangeWareHouse = (selectedOption) => {
    setwareHouseId1(selectedOption.value);
    setwareHouseId(selectedOption.label);
    props.categoryByWareHouse(selectedOption.value);
  };

  const handleChangeCategory = (selectedOption) => {
    setcategoryId1(selectedOption.value);
    setcategoryId(selectedOption.label);
    props.subCategoryByCategory(selectedOption.value);
  };

  const handleChangeSubCategory = (selectedOption) => {
    setSubCategoryId(selectedOption.value);
    setSubCategoryId1(selectedOption.label);
  };
  const handleChangeVendor = (selectedOption) => {
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };

  const handleChangeInventoryField = (selectedOption) => {
    setInvField(selectedOption.value);
    setInvFieldLabel(selectedOption.label);
  };

  const handleChangeProductType = (selectedOption) => {
    setProductType(selectedOption.value);
    setProductTypeLabel(selectedOption.label);
  };

  const handleChangeCurrency = (selectedOption) => {
    setCurrency(selectedOption.value);
    setCurrencyLabel(selectedOption.label);
  };

  const getInvMasterControlList = props.invMasterControlList.map((x) => {
    let data = { value: x.id, label: x.name,type:x.type };
    return data;
  });

  const handleChangeColor = (selectedOption) => {
    setColorId(selectedOption.value);
    setColor(selectedOption.label);
  };
  const handleChangeModal = (selectedOption) => {
    setModalId(selectedOption.value);
    setModal(selectedOption.label);
  };
  const handleChangeSize = (selectedOption) => {
    setSizeId(selectedOption.value);
    setSize(selectedOption.label);
  };

  useEffect(() => {
    props.getVendor()
    props.getMasterControl();
    props.getWarehouseData();
    props.getInvMasterControl();
    setwareHouseId("");
    setcategoryId("");
    setSubCategoryId("");
    setSubCategoryId1("");
  }, []);
  return (
    <>
      {props.isFetchingwareHouseList ||
        props.isFetchingGetVendor ||
        props.isFetchingInvMasterControl ||
        props.isFetchingcategoryList ||
        props.isFetchingsubCategoryList ||
        props.isFetchingInventoryGetdata ||
        props.isAddingInventory ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingInventory ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="3" lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>
                      {" "}
                      Select Warehouse{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  <Select
                    placeholder="Select WareHouse..."
                    value={{ label: wareHouseId }}
                    onChange={(e) => handleChangeWareHouse(e)}
                    options={getWareHouseList}
                  />
                </Form.Group>
              </Col>

              <Col xl="3" lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>
                      {" "}
                      Select Category{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(getcategoryList) || isEmpty(wareHouseId)
                    }
                    value={{ label: categoryId }}
                    placeholder="Select Category..."
                    onChange={(e) => handleChangeCategory(e)}
                    options={getcategoryList}
                  />
                </Form.Group>
              </Col>

              <Col xl="3" lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>
                      {" "}
                      Select SubCategory{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(getSubCategoryList) || isEmpty(categoryId)
                    }
                    value={{ label: subCategoryId1 }}
                    placeholder="Select SubCategory..."
                    onChange={(e) => handleChangeSubCategory(e)}
                    options={getSubCategoryList}
                  />
                </Form.Group>
              </Col>
              <Col xl="3" lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>
                      {" "}
                      Select Vendor{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(getVendorList) || isEmpty(subCategoryId1)
                    }
                    value={{ label: vendorName }}
                    placeholder="Select Vendor..."
                    onChange={(e) => handleChangeVendor(e)}
                    options={getVendorList}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <br />
            <br /> */}
            <Row>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Company Name </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Company Name"
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Company Name"
                      onBlur={(e) => setCompanyName(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Inventory Field </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Select placeholder="Select InputField..." isDisabled />
                  ) : (
                    <Select
                      value={{ label: invFieldLabel }}
                      placeholder="Select InputField..."
                      onChange={(e) => handleChangeInventoryField(e)}
                      options={getInventoryField}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>
                      {" "}
                      Select Product Type{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>{" "}
                    </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Select placeholder="Select Product Type..." isDisabled />
                  ) : (
                    <Select
                      value={{ label: productTypeLabel }}
                      placeholder="Select Product Type..."
                      onChange={(e) => handleChangeProductType(e)}
                      options={getProductType}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Inventory Name{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Inventory Name...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Inventory Name...."
                      onBlur={(e) => setInventoryName(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Add Price{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>{" "}
                    </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="number"
                      placeholder="Add Price...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="number"
                      placeholder="Add Price...."
                      onBlur={(e) => setPrice(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>
                      {" "}
                      Select Currency{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>{" "}
                    </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Select placeholder="Select Currency..." isDisabled />
                  ) : (
                    <Select
                      value={{ label: currencyLabel }}
                      placeholder="Select Currency..."
                      onChange={(e) => handleChangeCurrency(e)}
                      options={getCurrency}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Current Balance{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="number"
                      placeholder="Current Balance...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="number"
                      placeholder="Current Balance...."
                      onBlur={(e) => setCurrentBalance(Number(e.target.value))}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Add Unit of Measure{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Unit of Measure...."
                      readOnly
                    />
                  ) : (
                    <Select
                      value={{ label: masterControlLabel }}
                      placeholder="Unit of Measure..."
                      onChange={(e) => handleChangeMasterControl(e)}
                      options={getMasterControlList}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>
                      Select Product Arrival Date{" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control type="date" readOnly></Form.Control>
                  ) : (
                    <Form.Control
                      type="date"
                      value={arrivalDate}
                      onChange={(e) => setArrivalDate(e.target.value)}
                    ></Form.Control>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Model
                    {" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1)||isEmpty(getInvMasterControlList) ? (
                    <Select
                      value={{ label: modal }}
                      isDisabled
                      placeholder="Select Model..."
                      onChange={(e) => handleChangeModal(e)}
                      options={getInvMasterControlList}
                    />
                  ) : (
                    <Select
                      value={{ label: modal }}
                      placeholder="Select Modal..."
                      onChange={(e) => handleChangeModal(e)}
                      options={filter(getInvMasterControlList,['type','Model'])}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Size {" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span></b>
                  </Form.Label>
                  {isEmpty(subCategoryId1)||isEmpty(getInvMasterControlList) ? (
                    <Select
                      value={{ label: size }}
                      isDisabled
                      placeholder="Select Size..."
                      onChange={(e) => handleChangeSize(e)}
                      options={filter(getInvMasterControlList,['type','Size'])}
                    />
                  ) : (
                    <Select
                      value={{ label: size }}
                      placeholder="Select Size..."
                      onChange={(e) => handleChangeSize(e)}
                      options={filter(getInvMasterControlList,['type','Size'])}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Color {" "}
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span></b>
                  </Form.Label>
                  {isEmpty(subCategoryId1)||isEmpty(getInvMasterControlList) ? (
                      <Select
                      value={{ label: color }}
                      isDisabled
                      placeholder="Select Color..."
                      onChange={(e) => handleChangeColor(e)}
                      options={filter(getInvMasterControlList,['type','Color'])}
                    />
                  ) : (
                    <Select
                    value={{ label: color }}
                    placeholder="Select Color..."
                    onChange={(e) => handleChangeColor(e)}
                    options={filter(getInvMasterControlList,['type','Color'])}
                  />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Minimum Balance </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="number"
                      placeholder="Minimum Balance...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="number"
                      placeholder="Minimum Balance...."
                      onBlur={(e) => setMiniBalance(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Maximum Balance</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="number"
                      placeholder="Maximum Balance...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="number"
                      placeholder="Maximum Balance...."
                      onBlur={(e) => setMaxBalance(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add RACK Position</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Rack Position...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Rack Position...."
                      onBlur={(e) => setRackPosition(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Box Number</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Box No...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Box No...."
                      onBlur={(e) => setBoxNo(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Height</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Height...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Height...."
                      onBlur={(e) => setHeight(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Width</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Width...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Width...."
                      onBlur={(e) => setWidth(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Lenght</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Lenght...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Lenght...."
                      onBlur={(e) => setLength(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Thickness</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="Thickness...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Thickness...."
                      onBlur={(e) => setThickness(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add HS Code</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      type="text"
                      placeholder="HS Code...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="HS Code...."
                      onBlur={(e) => setHsCode(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Additional Description </b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description ....."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description 1....."
                      onBlur={(e) => setDescription(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Additional Description 2</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description 2....."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description 2....."
                      onBlur={(e) => setDescription2(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Additional Description 3</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description 3....."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description 3....."
                      onBlur={(e) => setDescription3(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Additional Description 4</b>
                  </Form.Label>
                  {isEmpty(subCategoryId1) ? (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description 4....."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description 4....."
                      onBlur={(e) => setDescription4(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row> */}
            <div className="sendDiv">
              {(isEmpty(wareHouseId) &&
                isEmpty(categoryId) &&
                isEmpty(subCategoryId)) ||
                isEmpty(vendorName) ||
                isEmpty(inventoryName) ||
                isEmpty(price) ||
                modalId==''||sizeId==''||colorId==''||
                isEmpty(arrivalDate) ||
                currentBalance==0 ||
                isEmpty(productType) ||
                isEmpty(currency) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadInventory}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryData: () => dispatch(getCategoryData()),
    getWarehouseData: () => dispatch(getWarehouseData()),
    getsubCategoryData: () => dispatch(getsubCategoryData()),
    addInventory: (data, handleVisible, handleDangerVisible) =>
      dispatch(addInventory(data, handleVisible, handleDangerVisible)),
    getInventory: (id) => dispatch(getInventory(id)),
    deleteInventory: (inventoryId) => dispatch(deleteInventory(inventoryId)),
    updateInventory: (newData, oldData) =>
      dispatch(updateInventory(newData, oldData)),
    resetState: () => dispatch(resetReducer()),
    uploadFile: (data, handleVisible, handleDangerVisible) =>
      dispatch(uploadFile(data, handleVisible, handleDangerVisible)),
    categoryByWareHouse: (warehouseId) =>
      dispatch(categoryByWareHouse(warehouseId)),
    subCategoryByCategory: (categoryId) =>
      dispatch(subCategoryByCategory(categoryId)),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    getMasterControl: () => dispatch(getMasterControl()),
    getVendor: () => dispatch(getVendor()),

    getInvMasterControl: () => dispatch(getInvMasterControl()),
    // getInvSize: () => dispatch(getInvSize()),
    // getInvModal: () => dispatch(getInvModal()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  vendorInfo: state.vendorReducer.vendorInfo,
  isFetchingGetVendor: state.vendorReducer.isFetchingGetVendor,
  warehouseList: state.AddDataReducer.warehouseList,
  categoryList: state.AddDataReducer.categoryList,
  subCategoryList: state.AddDataReducer.subCategoryList,
  inventoryList: state.AddDataReducer.inventoryList,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  isFetchingcategoryList: state.AddDataReducer.isFetchingcategoryList,
  isFetchingsubCategoryList: state.AddDataReducer.isFetchingsubCategoryList,
  isFetchingInventoryGetdata: state.AddDataReducer.isFetchingInventoryGetdata,
  dataAddedInventory: state.AddDataReducer.dataAddedInventory,
  isFetchingUploadFile: state.AddDataReducer.isFetchingUploadFile,
  addUploadFile: state.AddDataReducer.addUploadFile,
  categoryByWareHouseValue: state.AddDataReducer.categoryByWareHouseValue,
  subCategoryByCategoryValue: state.AddDataReducer.subCategoryByCategoryValue,
  allInventory: state.AddDataReducer.allInventory,
  isAddingInventory: state.AddDataReducer.isAddingInventory,
  masterControlList: state.AddDataReducer.masterControlList,
  isFetchingmasterControlList: state.AddDataReducer.isFetchingmasterControlList,

  isFetchingInvMasterControl: state.AddDataReducer.isFetchingInvMasterControl,
  invMasterControlList: state.AddDataReducer.invMasterControlList,
  // invModalList: state.AddDataReducer.invModalList,
  // invColorList: state.AddDataReducer.invColorList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInventory);
