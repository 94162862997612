import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import "../add-details.css";
import { ModalBody, Modal } from "reactstrap";
import {
  getPurchase,
  getApproveDemand,
  addIssuance,
  getAllInventory,
  getReceiving,
  searchReceivingReport,
  searchReceivingReportByDate,
  searchReceivingReportByVendor,
} from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getVendor } from "../Vendor/vendor.action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const ReceivingDetailWithVendor = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      productQuantity: 0,
      inventoryLabel: "",
    },
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [requistionLabel, setRequistionLabel] = useState();
  const [department, setdepartment] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [vendorName, setVendorName] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Data Not Found",
      text: "Data Not Found",
      icon: "info",
      button: "Ok",
    });
  };

  const handleChangeGetIssuance = (selectedOption) => {
    setIssuanceNameValue(selectedOption.value);
    setIssuanceNameLabel(selectedOption.label);
  };

  // const getReceivingList = props.ReceivingList.map((x) => { let data = { value: x.receiving_id, label: `${x.receiving_id}/${x.receivedBy}/${x.issuedBy}` }; return data })

  const exportPDF = () => {
    props.searchReceivingReport(
      {
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        vendorId:vendorId
        //  receiving_id : issuanceNameValue
      },
      handleVisible
    );
    setStartDate("");
    setEndDate("");
    //  setIssuanceNameLabel("")
  };

  //const inventory = props.allInventory.map((x) => { let data = { value: x.inventoryId, label: x.warehouse.whKey + "-" + x.category.cateKey + "-" + x.invKey + " " + x.productName }; return data })

  const handleChangeDemand = (e, index, maxVal, type) => {
    if (Number(e.target.value) <= maxVal) {
      let z = find(props.allInventory, (x) => x.inventoryId == e.value);
      const { name, value } = e;
      const list = [...inputField];
      if (type == "intname") list[index]["inventoryId"] = e.value;
      if (type == "intname")
        list[index]["productQuantity"] = Number(z.currentBlnc);
      if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
      if (type == "intquantity")
        list[index]["productQuantity"] = e.target.value;
      var holder = {};
      list.forEach(function (d) {
        if (holder.hasOwnProperty(d.inventoryId)) {
          holder[d.inventoryId] =
            Number(d.productQuantity) + Number(d.productQuantity);
        } else {
          holder[d.inventoryId] = Number(d.productQuantity);
        }
      });
      var obj2 = [];
      for (var prop in holder) {
        obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]) });
      }
      setInputField(obj2);
    } else {
      alert("value out of range");
    }
  };

  const handleChange = (e, index, type) => {
    let z = find(props.allInventory, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname")
      list[index]["productQuantityCount"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    setInputField(list);
    list[index]["productQuantity"] = "";
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", productQuantityCount: "", productQuantity: "" },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  const getRequistion = [
    { value: "Manual", label: "Manual" },
    { value: "After Production", label: "After Production" },
  ];

  const handleChangeRequistion = (selectedOption) => {
    setRequistion(selectedOption.value);
    setRequistionLabel(selectedOption.label);
  };

  const wareHouseData = props.IssuanceList;
  const [state, setState] = React.useState({
    viewColumns: [
      { title: "Issuance Number", field: "issuanceId" },
      { title: "Requistion", field: "requsition" },
      { title: "Department", field: "department" },
      { title: "Issued By", field: "issuedBy" },
      { title: "Received By", field: "receivedBy" },
      { title: "Requistion Type", field: "requsitionType" },
    ],
    columnsData: [
      {
        title: "Inventory Code",
        field: "inventory",
        render: (rowData) =>
          rowData.inventory.warehouse.whKey +
          "-" +
          rowData.inventory.category.cateKey +
          "-" +
          rowData.inventory.invKey,
      },
      { title: "Inventory Name", field: "inventory.productName" },
      { title: "Product Quantity", field: "productQuantity" },
    ],
  });
  const getVendorList =
  !isEmpty(props.vendorInfo) &&
  props.vendorInfo.map((x) => {
    let data = { value: x.vendor_id, label: x.vendor_name };
    return data;
  })
const handleChangeVendor = (selectedOption) => {
  setVendorId(selectedOption.value);
  setVendorName(selectedOption.label);
};
const history = useHistory();
const tableRef = useRef();
function updateTableQuery() {
    tableRef.current.onQueryChange()
};
  useEffect(() => {
    props.getVendor();
  }, []);
  return (
    <>
      {props.isFetchingGetVendor ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col >
                <FormGroup>
                  <Form.Label>
                    <b>Start Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col >
                <FormGroup>
                  <Form.Label>
                    <b>End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col style={{zIndex:1}}>
              <Form.Group>
                  <Form.Label>
                    <b>Select Vendor</b>
                  </Form.Label>
                  <Select 
                  style={{zIndex:4}}
                    isDisabled={
                      isEmpty(getVendorList)}
                    value={{ label: vendorName }}
                    placeholder="Select Vendor..."
                    onChange={(e) => handleChangeVendor(e)}
                    options={getVendorList}
                  // onBlur={() => setStateValue(false)}
                  />
                </Form.Group>
                </Col>
            </Row>
            <div className="sendDiv">

              {vendorId=="" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Search
                </Button>
              ) : props.isFetchingReceivingWithVendor ? (
                <Button className="sendButton">Searching</Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Search
                </Button>
              )}
            </div>
            {console.log(props.receivingWithVendor,"uuuuuuuuuuuuuuuuuuuuuu")}
            {isEmpty(props.receivingWithVendor)?
            <b>No Data Found </b>
            :
            <div className="main" >
            <MaterialTable style={{zIndex:0}}
              title="Good Received Note Details"
              //columns={state.viewColumns}
              columns={[
                { title: "Sr Number", field: "receiving_id" },
                { title: "Date", field: "receiving_date" },
                // { title: "Company", field: "department" },
                // { title: "Issued By", field: "issuedBy" },
                {title:'Vendor Name',field:'vendor.vendor_name',        
                  // render: (rowData) =>
                  // rowData.vendor.vendor_id +
                  // "-" +
                  // rowData.vendor.vendor_name 
                },
                { title: "Received By", field: "receivedBy" },
                { title: "Description", field: "reciving_description" },
                { title: "Receiving Total", field: "receiving_total" },
                
              ]}
              data={props.receivingWithVendor}
              // data={isEmpty(wareHouseData) ? [] : wareHouseData.reverse()}
              // data={(query) =>
              //   new Promise((resolve, reject) => {
              //     console.log(query, "qr");
              //     let url = `${baseURL}receiving/get-all-receiving?`;
              //     url += "size=" + query.pageSize;
              //     url += "&page=" + (query.page + 1);
              //     url += "&search=" + query.search;
              //     fetch(url, {
              //       method: "GET",
              //       headers: headers,
              //     })
              //       .then((response) => response.json())
              //       .then((result) => {
              //         resolve({
              //           data: result.data,
              //           page: result.page - 1,
              //           totalCount: result.total_results,
              //         });
              //       });
              //   })
              // }
              actions={[
                {
                icon: 'edit',
                  tooltip: 'Edit GRN/PO',
                  onClick: (event, rowData) => {
                    // Do save operation
                  //   setcallEdit(true);
                  props.getReceivingById(rowData.receiving_id)
                  history.push('/user/edit-receiving')
                  //  props.selectedUser(rowData)
                  }
                }
            ]}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteReceiving(oldData.receiving_id,updateTableQuery);
                    }, 600);
                  }),
              }}
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    //columns={state.columnsData}
                    columns={[
                      {
                        title: "Inventory Code",
                        field: "inventory",
                        render: (rowData) =>
                          rowData.inventory.warehouse.whKey +
                          "-" +
                          rowData.inventory.category.cateKey +
                          "-" +
                          rowData.inventory.invKey,
                      },
                      {
                        title: "Inventory Name",
                        field: "inventory.productName",
                      },
                      { title: "Inventory Price", field: "inventoryPrice" },
                      { title: "Inventory Quantity", field: "productQuantity" },
                    ]}
                    data={rowData.receiving_data}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: true,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                filtering: false,
                exportButton: true,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>}
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVendor: () => dispatch(getVendor()),
    searchReceivingReport: (data,handleVisible) =>
      dispatch(
        searchReceivingReportByVendor(data, handleVisible)
      ),
  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  purchaseList1: state.AddDataReducer.purchaseList1,
  approveDemandList: state.AddDataReducer.approveDemandList,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  ReceivingList: state.AddDataReducer.ReceivingList,
  isFetchingIssuance: state.AddDataReducer.isFetchingIssuance,
  isFetchingSearchIssuanceList:
    state.AddDataReducer.isFetchingSearchIssuanceList,
    vendorInfo: state.vendorReducer.vendorInfo,
    isFetchingGetVendor: state.vendorReducer.isFetchingGetVendor,
    receivingWithVendor: state.AddDataReducer.receivingWithVendor,
    isFetchingReceivingWithVendor: state.AddDataReducer.isFetchingReceivingWithVendor,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceivingDetailWithVendor);
