import API from "../../../../global/api";
import { isEmpty, isNull, filter, sumBy } from "lodash";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
import { textData } from "../../../../global/test";
const _ = require("lodash");
export const requestSearchLedger = () => {
  return {
    type: "REQUEST_ADD_LEDGER",
  };
};
export const successSearchLedger = (data) => {
  return {
    type: "SUCCESS_ADD_LEDGER",
    payload: data,
  };
};
export const errorSearchLedger = () => {
  return {
    type: "ERROR_ADD_LEDGER",
  };
};

export const SearchLedger = () => {
  return (dispatch) => {
    dispatch(requestSearchLedger());
    API.get(`/inventory/getInventoryHistory`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successSearchLedger(data1));
      })
      .catch((error) => {
        dispatch(errorSearchLedger());
      });
  };
};

export const requestSearchLedgerByDate = () => {
  return {
    type: "REQUEST_ADD_LEDGER_BY_DATE",
  };
};
export const successSearchLedgerByDate = (data) => {
  return {
    type: "SUCCESS_ADD_LEDGER_BY_DATE",
    payload: data,
  };
};
export const errorSearchLedgerByDate = () => {
  return {
    type: "ERROR_ADD_LEDGER_BY_DATE",
  };
};

export const SearchLedgerByDate = (data) => {
  return (dispatch) => {
    dispatch(requestSearchLedgerByDate());
    API.post(`/inventory/getinventoryhistoryByDate`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successSearchLedgerByDate(data1));
      })
      .catch((error) => {
        dispatch(errorSearchLedgerByDate());
      });
  };
};

export const requestLevel5LedgerData = () => {
  return {
    type: "REQUEST_LEVEL5_LEDGER_DATA",
  };
};
export const successLevel5LedgerData = (data) => {
  return {
    type: "SUCCESS_LEVEL5_LEDGER_DATA",
    payload: data,
  };
};
export const errorLevel5LedgerData = () => {
  return {
    type: "ERROR_LEVEL5_LEDGER_DATA",
  };
};

export const getLevel5LedgerData = () => {
  return (dispatch) => {
    dispatch(requestLevel5LedgerData());
    API.get(`/levelFive/get-acc-ledger-user`)
      .then((res) => {
        let getData = res.data;
        dispatch(successLevel5LedgerData(getData));
      })
      .catch((error) => {
        dispatch(errorLevel5LedgerData());
      });
  };
};

export const requestSearchAccountLedger = () => {
  return {
    type: "REQUEST_SEARCH_LEDGER",
  };
};
export const successSearchAccountLedger = (data) => {
  return {
    type: "SUCCESS_SEARCH_LEDGER",
    payload: data,
  };
};
export const errorSearchAccountLedger = () => {
  return {
    type: "ERROR_SEARCH_LEDGER",
  };
};

export const searchAccountLedger = (data, startDate, endDate) => {
  return (dispatch) => {
    dispatch(requestSearchAccountLedger());
    API.post(`/levelFive/get-acc-data-user`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successSearchAccountLedger(getData));
      })
      .catch((error) => {
        dispatch(errorSearchAccountLedger());
      });
  };
};

export const requestSearchAccountLedgerReport = () => {
  return {
    type: "REQUEST_SEARCH_LEDGER_REPORT",
  };
};
export const successSearchAccountLedgerReport = (data) => {
  return {
    type: "SUCCESS_SEARCH_LEDGER_REPORT",
    payload: data,
  };
};
export const errorSearchAccountLedgerReport = () => {
  return {
    type: "ERROR_SEARCH_LEDGER_REPORT",
  };
};

export const searchAccountLedgerReport = (data, startDate, endDate) => {
  return (dispatch) => {
    let name = data.name;
    dispatch(requestSearchAccountLedgerReport());
    API.post(`/levelFive/get-pdf`, data)
      .then((res) => {
        let getData = res.data;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        // doc.addFileToVFS('Amiri-Regular.ttf', textData);
        // doc.addFont('Amiri-Regular.ttf', 'Amiri-Regular', 'normal');
        // doc.setFont('Amiri-Regular');

        const title = `${name} (LEDGER ACCOUNT)`;
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const Dates =
          !isEmpty(startDate) || !isEmpty(endDate)
            ? "Form: " + " " + startDate + "  to:  " + endDate
            : "Form: -  To: - ";
        let TotalCredit = "";
        let TotalDebit = "";
        const Rarray = getData.data.slice(-1);
        let TotalBalance = "";
        if (getData.data.length <= 1) {
          TotalCredit = "Total Credit: 0";
          TotalDebit = "Total Debit: 0";
          TotalBalance = `Closing Balance: ${getData.data[0].accBalance.toLocaleString()} ${
            getData.data[0].accType == 2 ? "DEBIT" : "CREDIT"
          }`;
        } else {
          TotalCredit = "Total Credit: " + getData.credit_sum;
          TotalDebit = "Total Debit: " + getData.debit_sum;
          TotalBalance = `Closing Balance: ${Rarray[0].accBalance.toLocaleString()} ${
            Rarray[0].accType == 2 ? "DEBIT" : "CREDIT"
          }`;
        }
        const headers = [
          [
            "DATE",
            "VOUCHER#",
            "DESCRIPTION",
            "BILL NO",
            "DEBIT",
            "CREDIT",
            "BALANCE",
            "DR/CR",
          ],
        ];
        const data = getData.data.map((x) => [
          x.voucher_date,
          x.voc_manual_number,
          x.voucher_description,
          x.voucher_bill_no,
          x.debit,
          x.credit,
          x.accBalance,
          x.accType == 2 ? "DR" : "CR",
        ]);
        let content = {
          startY: 240,
          //    startX: 5,
          columnStyles: {
            0: { cellWidth: 60 },
            1: { cellWidth: 65 },
            2: { cellWidth: 170 },
            3: { cellWidth: 60 },
            4: { cellWidth: 50 },
            5: { cellWidth: 50 },
            6: { cellWidth: 60 },
            7: { cellWidth: 45 },
          },
          //    styles:{cellWidth:100},
          head: headers,
          // tableWidth: "wrap",
          body: data,
          // styles: {
          //   font: 'Amiri-Regular',
          //   fontStyle: 'normal',
          // }
        };
        // doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 200, 60, 0, 20);
        doc.setFontSize(13);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 200, 80, 0, 20);
        doc.text(companyNtn, 220, 100, 0, 20);
        doc.setFontSize(14);
        doc.text(Dates, marginLeft, 160, 0, 0);
        doc.text(title, marginLeft, 140, 0, 0);
        doc.text(`${TotalCredit}`, marginLeft, 180);
        doc.text(`${TotalDebit}`, marginLeft, 200);
        doc.text(TotalBalance, marginLeft, 220);
        doc.setFontSize(12);
        doc.autoTable(content);
        doc.setFontSize(14);
        doc.setProperties({
          title: `Account Ledger Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}`,
        });
        //ct  doc.output('dataurlnewwindow')
        // doc.save(
        //   `${name} Account Ledger Report ${moment(new Date()).format(
        //     "YYYY-MM-DD"
        //   )}.pdf`
        // );
        // var string = doc.output('datauristring','dddd');
        // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
        // var x = window.open('Demand Report');
        // x.document.open('Demand Report');
        // x.document.write(embed);
        // x.document.close();

        //  console.log(getData.data,TotalBalance,"abc")
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
        dispatch(successSearchAccountLedgerReport(getData));
      })
      .catch((error) => {
        dispatch(errorSearchAccountLedgerReport());
      });
  };
};
export const requestSearchAccountCashBook = () => {
  return {
    type: "REQUEST_SEARCH_CASH_BOOK",
  };
};
export const successSearchAccountCashBook = (data) => {
  return {
    type: "SUCCESS_SEARCH_CASH_BOOK",
    payload: data,
  };
};
export const errorSearchAccountCashBook = () => {
  return {
    type: "ERROR_SEARCH_CASH_BOOK",
  };
};

export const searchAccountCashBook = (data, startDate, endDate, itemLabel) => {
  return (dispatch) => {
    dispatch(requestSearchAccountCashBook());
    API.post(`/cash_book/cash_book_report`, data)
      .then((res) => {
        let data1 = res.data;
        let getData = res.data.data;
        let dataD = _.filter(getData, { credit: null });
        let td = sumBy(dataD, "debit");
        let dataC = _.filter(getData, { debit: null });
        let tc = sumBy(dataC, "credit");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 10;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = `${itemLabel} Cash Book Report`;
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const Dates =
          !isEmpty(startDate) || !isEmpty(endDate)
            ? "Form: " + " " + startDate + "  to  " + endDate
            : "Form: -  To: - ";
        const openingBalance = `${
          isNaN(getData[0].opening_balance) ? 0 : getData[0].opening_balance
        } ${getData[0].accType == 1 ? "CR" : "DR"}`;
        const totalDebit = `${ data1.debit_sum}`;
        const totalCredit = `${
          data1.credit_sum
        }`;
        let i = getData.length - 1;
        const closingBalance = `${
          isNull(data1.closing_balance) ? 0 : data1.closing_balance
        }  ${data1.closing_balance_sign == 1 ? "CR" : "DR"}`;
        /* const accountTitle = "Account Title:  " + getData.head;
            const TotalCredit = "Total Credit: " + getData.credit_sum.toLocaleString();
            const TotalDebit = "Total Debit: " + getData.debit_sum.toLocaleString();
            const Rarray = getData.a.slice(-1);
            const TotalBalance = "Closing Balance: " + Rarray[0].accBalance.toLocaleString();*/
        //(${x.cross_acc})`, x.voucher_bill_no, isNull(x.debit) ? 0 : Number(x.debit).toLocaleString(), isNull(x.credit) ? 0 : Number(x.credit).toLocaleString(), Number(x.accBalance).toLocaleString(), x.accType == 2 ? "Debit" : x.accType == 1 ? "Credit" : ""
        const headers = [["DATE", "VOUCHER#", "NARRATION", "AMOUNT"]];
        const dataDebit = dataD.map((x, i) => [
          x.voucher_date,
          x.voc_manual_number,
          x.voucher_description,
          x.debit,
        ]);
        const dataCredit = dataC.map((x, i) => [
          x.voucher_date,
          x.voc_manual_number,
          x.voucher_description,
          x.credit,
        ]);
        // doc.addImage(logo, "PNG", 40, 10, 65, 65);
        doc.text(companyName, 110, 30, 0, 20);
        doc.text(companyAddress, 110, 50, 0, 20);
        doc.text(companyNtn, 110, 65, 0, 20);
        doc.text("DAILY CASH REPORT", 400, 35, 0, 20);
        doc.text(Dates, 400, 65, 0, 20);
        doc.setFontSize(14);
        // doc.setFont(undefined,'bold');
        doc.text(title, 90, 85, 0, 70);
        doc.setLineWidth(2);
        doc.line(550, 90, 35, 90);
        doc.setFontSize(12);
        doc.setFont(undefined, "normal");
        doc.text("OPENING BALANCE", 40, 110, 0, 20);
        doc.text(openingBalance, 470, 110, 0, 20);
        doc.text("RECEIPTS", 250, 125, 0, 20);
        let content = {
          startY: 135,
          startX: 10,
          head: headers,
          // tableWidth: "wrap",
          theme: "grid",
          headStyles: {
            fillColor: [13, 110, 253],
          },
          body: dataDebit,
        };
        doc.autoTable(content);
        let content1 = {
          startY: doc.lastAutoTable.finalY + 40,
          startX: 10,
          head: headers,
          // tableWidth: "wrap",
          theme: "grid",
          headStyles: {
            fillColor: [13, 110, 253],
          },
          body: dataCredit,
        };

        doc.text("PAYMENTS", 250, doc.lastAutoTable.finalY + 30, 0, 20);
        doc.text("Total Receipt", 43, doc.lastAutoTable.finalY + 15, 0, 0);
        doc.text(totalDebit, 455, doc.lastAutoTable.finalY + 15, 0, 0);
        /* doc.text(accountNo, marginLeft, 140, 0, 20);
            doc.text(accountTitle, marginLeft, 160, 0, 20);
            doc.text(title, marginLeft, 175);
            doc.text(TotalCredit, marginLeft, 190);
            doc.text(TotalDebit, marginLeft, 208);
            doc.text(TotalBalance, marginLeft, 225);*/
        doc.autoTable(content1);
        doc.text("Total Payment", 43, doc.lastAutoTable.finalY + 15, 0, 0);
        doc.text(totalCredit, 455, doc.lastAutoTable.finalY + 15, 0, 0);
        doc.text("CLOSING BALANCE", 40, doc.lastAutoTable.finalY + 30, 0, 0);
        doc.text(closingBalance, 470, doc.lastAutoTable.finalY + 30, 0, 0);
        doc.setLineWidth(2);
        doc.line(
          150,
          doc.lastAutoTable.finalY + 70,
          40,
          doc.lastAutoTable.finalY + 70
        );
        doc.text("Prepared By", 60, doc.lastAutoTable.finalY + 85, 0, 0);
        doc.setLineWidth(2);
        doc.line(
          240,
          doc.lastAutoTable.finalY + 70,
          350,
          doc.lastAutoTable.finalY + 70
        );
        doc.text("Checked By", 265, doc.lastAutoTable.finalY + 85, 0, 0);
        doc.setLineWidth(2);
        doc.line(
          430,
          doc.lastAutoTable.finalY + 70,
          540,
          doc.lastAutoTable.finalY + 70
        );
        doc.text("Approved By", 455, doc.lastAutoTable.finalY + 85, 0, 0);
        doc.setLineWidth(2);
        // doc.line(150,doc.lastAutoTable.finalY+470,35,doc.lastAutoTable.finalY+70)
        //  doc.text("Prepared By",340,doc.lastAutoTable.finalY+85,0,0)
        //   doc.text(title, marginLeft, 208);*/
        doc.setProperties({
          title: `${itemLabel} Cash book ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
        // doc.output("dataurlnewwindow");
        // doc.save(
        //   `Cash Book Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        // );
        // var string = doc.output("datauristring", "dddd");
        // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
        // var x = window.open("Demand Report");
        // x.document.open("Demand Report");
        // x.document.write(embed);
        // x.document.close();
        dispatch(successSearchAccountCashBook(getData));
      })
      .catch((error) => {
        dispatch(errorSearchAccountCashBook());
      });
  };
};
