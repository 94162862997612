import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddVendor from "./addVendor";
import ViewVendors from "./viewVendors";


const TotalVendorsData = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 125) ?
                    "AddVendor" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 126) ?
                        "VendorList" :  ""} id="AddVendor" className="mb-3">
      {/* <Tabs defaultActiveKey={"AddVendor"} id="AddVendor" className="mb-3"> */}
        <Tab
          eventKey="AddVendor"
          title="Add Vendor"
          // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
        >
          <AddVendor />
        </Tab>
        <Tab
          eventKey="VendorList"
          title="Vendor List"
          //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
        >
          <ViewVendors />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalVendorsData);
