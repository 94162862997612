import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddReturn from "./AddReturn";
import ReturnDetails from "./ReturnDetails";

const TotalReturn = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      <Tabs
        defaultActiveKey={
          // "addreturn"
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 123)
            ? "addreturn"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 124)
            ? "getreturn"
            : ""
        }
        id="return"
        className="mb-3"
        unmountOnExit={true}
      >
          <Tab
          eventKey="addreturn"
          title="Add Return"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 123)
              ? false
              : true
          }
        >
          <AddReturn />
        </Tab>
        <Tab
          eventKey="getreturn"
          title="Return List"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 124)
              ? false
              : true
          }
        >
          <ReturnDetails />
        </Tab>
      

        {/* <Tab
          eventKey="RecReport"
          title="GRN/PO Report By Id"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 76)
              ? false
              : true
          }
        >
          <ReceivingReport />
        </Tab> */}
        {/* <Tab
          eventKey="RecReportBYDate"
          title="GRN/PO Report By Date"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 76)
              ? false
              : true
          }
        >
          <ReceivingReportByDate />
        </Tab> */}
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalReturn);
